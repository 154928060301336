// minimal
$brand-primary:      #685D9F;

@import "./variables.scss";

// misc typography
$body-color:           #000;
$headings-font-weight: 100;
$btn-font-weight:      300;
$letter-spacing-base:  -.02em;


// lead
$lead-letter-spacing: $letter-spacing-base;
$lead-font-size:      $font-size-base * 1.5;

// blocks
$block-title-font-size-sm: $font-size-base * 5.5;
$block-title-font-size:    $font-size-base * 3.5;
$block-lead-font-size:  $font-size-base * 1.25;


// navbars
$navbar-toggler-border-color: transparent;
$navbar-toggler-hover-bg:     transparent;
$navbar-border:               transparent;
$navbar-brand-color:          $gray-dark;

$nav-bordered-color:              #000;
$nav-bordered-color-active:       $brand-primary;
$nav-bordered-font-weight:        300;
$nav-bordered-font-weight-active: 700;

$navbar-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#685D9F' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

$input-height-base: 39px;

body{
    font-weight: 300;
    color: black;
}

p, li{
    font-size: $font-size-lg;
}

.navbar{
    background-color: rgba(255,255,255,0.9);
}

.navbar-nav {
    .nav-link {
        font-size: $font-size-sm * 1.4;
        font-weight: 300;
        color:#555;
    }
}

@import "./components.scss";

h1.block-title{
    font-size: $font-size-base * 4;
}

.navbar-light .navbar-toggler {
    border-color: transparent;
}
.navbar-toggler-left {
    margin-top: -45px;
    left: -15px;
}

.block-footer{
    background-color: #505050;
    color: rgba(116, 119, 123, .5);   
}

.box-img{
    width: 100%;
}

#scaggiano-wrapper{
    display: block;
    text-decoration: none;
    margin: 5px auto 0 auto;
    width: 200px;
    
    #page-developed-by{
        text-align: center;
        text-decoration: none;
        color: white;
    }
    
    #page-developed-by:visited{
        color: white;
    }
    
    #logo-chars {
        position: relative;
        width: 30px;
        height: 30px;
        margin: 10px auto;
        display: block;
        color: white;
        background-image: url('../img/logo-sc.png');
        background-size: cover;
    }
}

.block-footer{
    color: white;
}

.block-footer li{
    font-size: 14px;
}

.dropdown-menu {
    background-color: rgba(250,250,250,1);
    padding: 10px 20px;
    border: none;
    
    .dropdown-item {
        font-weight: 100;
        margin: 0;
        padding: 10px;
    }
    
    .dropdown-item:hover{
        background-color: transparent;
        text-decoration: underline;
    }
}

.navbar-toggler-right{
    right: 0;
}

#main-block{
    background-image: url('../img/back_1.jpg');
    background-position:center;
    background-size: cover;
    height: 400px;
}

#main-text{
    font-size: $font-size-lg * 1.5;
    background-color: rgba(0,0,0,0.5);
    color:white;
}

#contact-form input{
    font-size: 16px;
    font-weight: 100;
}

#contact-form input:hover {
    border-color: #aaa;
}

.nav-item span{
    cursor: default;
}

@include media-breakpoint-down(sm) {
    #main-text{
        font-size: $font-size-lg;
    }
    
    p{
        font-size: $font-size-lg;
    }
    
    .block-footer ul li{
        font-size: $font-size-sm * 1.2;
    }
}